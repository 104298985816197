
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as profileCyQDkm5msUMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/profile.vue?macro=true";
import { default as authenticateNBSNBOJ2xeMeta } from "/opt/atlassian/pipelines/agent/build/pages/authenticate.vue?macro=true";
import { default as contact_45usfh16CA1puhMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact-us.vue?macro=true";
import { default as housep6bLGXudmKMeta } from "/opt/atlassian/pipelines/agent/build/pages/house.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91_91slug_93_935rmjEF4MLgMeta } from "/opt/atlassian/pipelines/agent/build/pages/info/[[slug]].vue?macro=true";
import { default as maintenance5s9pKqcvvoMeta } from "/opt/atlassian/pipelines/agent/build/pages/maintenance.vue?macro=true";
import { default as _91_91vin_93_93edrFOBR2MRMeta } from "/opt/atlassian/pipelines/agent/build/pages/rca/[[registration_plate]]/[[vin]].vue?macro=true";
import { default as travelXoTodzSx64Meta } from "/opt/atlassian/pipelines/agent/build/pages/travel.vue?macro=true";
export default [
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileCyQDkm5msUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/profile.vue")
  },
  {
    name: "authenticate",
    path: "/authenticate",
    meta: authenticateNBSNBOJ2xeMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authenticate.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact-us.vue")
  },
  {
    name: "house",
    path: "/house",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/house.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug?",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/info/[[slug]].vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/maintenance.vue")
  },
  {
    name: "rca-registration_plate-vin",
    path: "/rca/:registration_plate?/:vin?",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rca/[[registration_plate]]/[[vin]].vue")
  },
  {
    name: "travel",
    path: "/travel",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/travel.vue")
  }
]