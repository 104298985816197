<template>
  <master-layout class="login">
    <v-container class="content-container background-gradient">
      <v-container fluid class="component-container-1400mw">
        <authentication-container
        ></authentication-container>
      </v-container>
    </v-container>
  </master-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import MasterLayout from '~/layouts/MasterLayout.vue';
import LoadingDialog from "~/components/utils/LoadingDialog.vue";
import AuthenticationContainer from "~/components/auth/AuthenticationContainer.vue";

export default defineComponent({
  components: {AuthenticationContainer, LoadingDialog, MasterLayout},
  setup() {
    definePageMeta({
      middleware: [
        'guest',
      ],
    });

    useHead({
      title: '24Broker | Autentificare',
      meta: [
        {
          name: 'description',
          content: 'Autentifică-te sau creează-ți un cont nou pe 24Broker.ro pentru a accesa toate ofertele de asigurare și a le gestiona într-un singur loc.'
        }
      ],
    });
  }
});
</script>

<style scoped>

</style>
