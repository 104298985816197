<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import MasterLayout from "~/layouts/MasterLayout.vue";
import {useAuthStore} from "~/plugins/store/auth";
import {translation, useTranslationStore} from "~/plugins/store/translations";
import {
  createDefaultCustomerForm,
  createDefaultUserData,
  createDefaultUserDataValidation,
  type CustomerForm,
  type UserDataForm,
  type UserDataValidation
} from "~/types/Customer";
import type {TranslationList} from "~/types/Utils";
import {recaptcha} from "~/composables/recaptcha";
import {useNuxtApp} from "#app";
import {useUserStore} from "~/plugins/store/user";
import {useReCaptcha} from "vue-recaptcha-v3";
import LoadingDialog from "~/components/utils/LoadingDialog.vue";

export default defineComponent({
  components: {LoadingDialog, MasterLayout},
  setup() {
    definePageMeta({
      middleware: [
        'auth',
      ],
    });

    useHead({
      title: '24Broker | Profil',
      meta: [
        {
          name: 'description',
          content: 'Accesează și gestionează contul tău 24Broker.ro. Verifică-ți polițele de asigurare, detaliile contului și istoricul comparatiilor.'
        }
      ],
    });
    // Utility function to introduce a delay
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const translationStore = useTranslationStore();
    const translationList = ref<TranslationList>({}); // Initialize translations as a reactive reference
    const authStore = useAuthStore();
    const user = ref(authStore.getUser());
    let accessToken = authStore.getAccessToken();
    const userDataForm = ref<UserDataForm>(createDefaultUserData());
    const validationUserDataStatus = ref<UserDataValidation>(createDefaultUserDataValidation());
    const {$toast} = useNuxtApp();
    const userStore = useUserStore();
    const recaptchaInstance = useReCaptcha();
    const showLoadingDialog = ref(false);
    const messageLoadingDialog = ref('');

    onMounted(async () => {
      translationList.value = await translationStore.getTranslations('ro', 'dashboard');
      if (user.value) {
        userDataForm.value.id = user.value.id;
        userDataForm.value.email = user.value.email;
        userDataForm.value.first_name = user.value.first_name;
        userDataForm.value.last_name = user.value.last_name;
        userDataForm.value.phone_number = user.value.phone_number;
      }
    });

    const handleValidationUserStatus = ({field, isValid}: { field: string, isValid: boolean }) => {
      validationUserDataStatus.value[field] = isValid;
    };
    const handleValidation = (field: string, status: boolean) => {
      if (!status) {
        console.log(`Validation failed for field: ${field}`);
        window.dispatchEvent(new CustomEvent('validate-customer', {detail: field}));
        return false;
      }
      return true;
    };

    const submitUserData = async () => {
      let isValid = true;
      showLoadingDialog.value = true;
      messageLoadingDialog.value = translation(translationList.value.customer_details_validating, 'Validăm datele introduse...');
      // Introduce a delay of 1.5 second (1500 milliseconds)
      await delay(1500);
      for (const field in validationUserDataStatus.value) {
        if (!handleValidation(field, validationUserDataStatus.value[field])) {
          isValid = false;
        }
      }
      if (!isValid) {
        showLoadingDialog.value = false;
        $toast.error(translation(translationList.value.fill_all_fields, 'Te rugăm să completezi toate câmpurile obligatorii corect!'));
        return;
      }

      messageLoadingDialog.value = translation(translationList.value.customer_details_saving, 'Salvăm datele introduse...');
      // Introduce a delay of 1.5 second (1500 milliseconds)
      await delay(1500);
      const token = await recaptcha(recaptchaInstance);
      if (token) {
        userDataForm.value.token = token;
      }
      userStore.updateUserData(userDataForm.value, <string>accessToken)
          .then(async (response) => {
            if (response.access_token) {
              await authStore.setUser(response);
            }
            $toast.success(translation(translationList.value.success, 'Datele au fost actualizate cu succes'));
            user.value = authStore.getUser();
            console.log(user.value);
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response && error.response.data && error.response.data && error.response.data.error
                && error.response.data.error.message) {
              $toast.error(error.response.data.error.message);
            } else {
              $toast.error(translation(translationList.value.error, 'A apărut o eroare'));
            }
          })
          .finally(() => {
            showLoadingDialog.value = false;
          });
    };

    return {
      user,
      accessToken,
      translationList,
      translation,
      handleValidationUserStatus,
      userDataForm,
      submitUserData,
      showLoadingDialog,
      messageLoadingDialog,
    }
  }
});
</script>

<template>
  <master-layout class="profile">
    <v-container class="content-container background-gradient">
      <v-container fluid class="component-container-1400mw">
        <v-container class="background-surface" style="max-width: 1200px;">
          <loading-dialog :message="messageLoadingDialog" :show-dialog="showLoadingDialog"/>
          <v-row class="mt-3">
            <v-col cols="12" sm="auto" md="6" class="d-flex flex-column justify-start centered-on-mobile">
              <h3 class="font-weight-bold">
                {{ translation(translationList.my_profile, 'Profilul meu') }}</h3>
              <p class="font-weight-regular">
                {{
                  translation(translationList.my_profile_helper, 'Aici puteți modifica datele personale')
                }}
              </p>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col cols="12" sm="12" md="6">
              <customer-last-name v-model="userDataForm.last_name"
                                  @validation-customer-status="handleValidationUserStatus"></customer-last-name>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <customer-first-name v-model="userDataForm.first_name"
                                   @validation-customer-status="handleValidationUserStatus"></customer-first-name>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center">
            <v-col cols="12" sm="12" md="6">
              <customer-email v-model="userDataForm.email"
                              @validation-customer-status="handleValidationUserStatus"></customer-email>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <customer-phone-number v-model="userDataForm.phone_number"
                                     @validation-customer-status="handleValidationUserStatus"></customer-phone-number>
            </v-col>
          </v-row>

          <v-row class="mt-10 mb-15">
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-btn
                  class="block-on-mobile"
                  color="primary"
                  size="x-large"
                  flat
                  rounded="0"
                  @click="submitUserData"
              >{{ translation(translationList.save_changes, 'Salvează modificarile') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </master-layout>
</template>

<style scoped>

</style>